import React, { useEffect } from 'react';
import logo_fhr from '../../assets/img/logo_fhr.png';
import espiral_derecho from '../../assets/img/espiral_derecho.png';
import espiral_izquierda from '../../assets/img/Header/espiral_izquierda_grande.png';
import punto_negro_fhr from '../../assets/img/Header/punto_negro_fhr.png';
import hermoso_ruido from '../../assets/img/Header/hermoso_ruido.png';

import { Link } from "react-scroll";
const Header = () => {




    useEffect(() => {
        const navbar = document.getElementById("menu");
        const sticky = navbar.offsetTop;

        const stickyMeny = () => {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }

        window.onscroll = function () { stickyMeny() };

    }, [])


    return (
        <header id="header">
                <img className="logo_fhr" src={logo_fhr} alt="Logo" />
                <img className="punto_negro_fhr" src={punto_negro_fhr} alt="punto"/>

            <div className="menu" id="menu">
                {/* Menu web */}
                <ul className="menu_web">
                    <Link style={{color: 'black'}} className="inscripcion" to="registro_artista" smooth={true}>GALERÍA</Link>
                </ul>


                {/* Menu Movil */}
                <ul className="menu_movil">
                    <Link style={{color: 'black'}} className="inscripcion" to="registro_artista" smooth={true}>GALERÍA</Link>
                </ul>

            </div>

            <img className="espiral_derecho" src={espiral_derecho} alt="circulos" />
            <img className="espiral_izquierda" src={espiral_izquierda} alt="circulos" />
            <img className="hermoso_ruido" src={hermoso_ruido} alt="ruido" />

        </header>
    );
}

export default Header;