import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/autenticacion/authContext'
import "../Artistas/Artistas.css"

import hermoso_ruido_header_login from '../../assets/img/Header/hermoso_ruido_header_login.png';
import espiral_derecho_oscuro from '../../assets/img/Header/espiral_derecho_oscuro.png';
import espiral_izquierda_grande_oscuro from '../../assets/img/Header/espiral_izquierda_grande_oscuro.png';
import icono_advertencia_login from '../../assets/img/icono_advertencia_login.png';

import { Redirect } from "react-router-dom";


const IniciarPerfil = () => {

    // Extraer valores del context
    const authContext = useContext(AuthContext);
    const { autenticado, mensaje, iniciarSesionPerfil } = authContext;


    // state para iniciar sesion
    const [admin, guardarAdmin] = useState({
        usuario: '',
        password: ''
    })

    const [cargando, guardarCargando] = useState(false);
    const [error, guardarError] = useState('');


    // extraer de administrador
    const { usuario, password } = admin


    useEffect(() => {
        if(mensaje){
             guardarError('El usuario o contraseña no son válidos')
 
             setTimeout(() => {
                 guardarError(null)
             }, 2000);
 
            guardarCargando(false)
         }
     
    }, [mensaje]);

    const onChange = e => {
        guardarAdmin({
            ...admin,
            [e.target.name]: e.target.value
        })
    }

    // iniciar sesion
    const onSubmit = e => {
        e.preventDefault();

        guardarCargando(true)

        // validar que no haya campos vacios
        if (usuario.trim() === '' || password.trim() === '') {
            guardarError('Todos los campos son obligatorios');
            setTimeout(() => {
                guardarError("")
            }, 2000);

           return guardarCargando(false)
        }

        // pasarlo al action
        iniciarSesionPerfil({ usuario, password })

    }

    const TPERFIL = localStorage.getItem('TPERFIL');

    if (TPERFIL) {
        return <Redirect to='/sesionespitch/perfil' />
    }


    return (
        <section id="login_admin">
            <div className="background_black"></div>


            <div style={{ position: "relative", height: '100vh' }}>

                <div className="header_top">
                    <img className="hermoso_ruido_header_login" src={hermoso_ruido_header_login} alt="Hermoso ruido" />
                    <img className="espiral_derecho_oscuro" src={espiral_derecho_oscuro} alt="Espiral derecho" />
                </div>

                <img className="espiral_izquierda_grande_oscuro" src={espiral_izquierda_grande_oscuro} alt="espiral izquierda" />
                
                <div className="mensaje_no_access">
                    <p>Para tener una mejor experiencia en las videoconferencias, te recomendamos ingresar a través de Google Chrome en un computador de escritorio o portátil. </p>
                </div>

                <form
                    onSubmit={onSubmit}
                >

                    <div className="error_login">
                        {error === '' || !error
                        ?
                        null
                        :
                        <p><img style={{width: "15px", marginRight: '10px'}} src={icono_advertencia_login} alt="alerta"/> {error} </p>
                        }
                    </div>

                    <div className="input_box">
                        <label htmlFor="usuario">[Usuario]</label>
                        <input
                            type="text"
                            name="usuario"
                            id="usuario"
                            value={usuario}
                            onChange={onChange}
                        />
                    </div>
                    <div className="input_box">
                        <label htmlFor="password">[Contraseña]</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={onChange}
                        />
                    </div>

                    {!cargando
                        ?
                        <input type="submit" value="Ingresar" />
                        :
                        <p style={{textAlign: 'right'}}>Cargando...</p>
                    }
                </form>
            </div>
        </section>
    );
}

export default IniciarPerfil;


