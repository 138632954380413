import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useParams } from "react-router";
import './Artistas.css'

import {
    Redirect
} from "react-router-dom";

import no_profile_image from '../../assets/img/no_profile_image.png'
import Header from './HeaderOscuro'

const Promotor = () => {

    let { id } = useParams();
    const [artista, guardarArtista] = useState({});
    const [cargado, guardarCargando] = useState(false);

    useEffect(() => {
        const obtenerArtista = async () => {
            try {
                const url = `https://hermoso-ruido.herokuapp.com/api/promotores/${id}`;
                const artistaDB = await axios.get(url);
                guardarArtista(artistaDB.data.promotor);
                setTimeout(() => {
                    guardarCargando(true)
                }, 500);

            } catch (error) {
                console.log(error)
            }
        }
        obtenerArtista();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const TPERFIL = localStorage.getItem('TPERFIL');
    const AID = localStorage.getItem('AID');
    if (!AID || !TPERFIL) {
        return <Redirect to='/sesionespitch/login' />
    }


    return (
        <section id="promotor">
            <div className="background_black"></div>
            <Header />
            {!cargado
                ?
                <h2 style={{
                    position: 'absolute',
                    color: 'white',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    Cargando...
                    </h2>
                :
                <div>


                    <div className="artista_brief movil">
                        <h2>{artista.nombre} </h2>


                        <p className="promotor_organizacion">
                            <span>{artista.organizacion}</span>
                            {/* <span className="cargo">[cargo]</span> */}

                        </p>


                        {!artista.imagenUrl
                            ?
                            <img src={no_profile_image} alt="Promotor" />

                            :
                            <img src={artista.imagenUrl} alt="Promotor" />
                        }

                        <div className="item">
                            <div>
                                <p>{artista.correo}</p>
                                <span>E-MAIL</span>
                            </div>

                        </div>

                        <div className="item">
                            <p>{artista.brief}</p>
                        </div>

                        <div className="item">
                            <div>
                                <a style={{ color: 'white' }} href={artista.url} target="_blank" without rel="noreferrer">{artista.url}</a>
                                <span>URL</span>
                            </div>
                        </div>

                    </div>


                    <div className="artista_brief desktop">
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: "50%" }}>
                                {!artista.imagenUrl
                                    ?
                                    <img src={no_profile_image} alt="Promotor" />

                                    :
                                    <img src={artista.imagenUrl} alt="Promotor" />
                                }


                            </div>


                            <div style={{ width: "50%", paddingLeft: '15px' }}>
                                <h2>{artista.nombre} </h2>

                                <p className="promotor_organizacion">
                                    <span>{artista.organizacion}</span>
                                    {/* <span className="cargo">[cargo]</span> */}

                                </p>

                                <div className="item url">
                                    <p>{artista.correo}</p>
                                    <span>E-MAIL</span>
                                </div>


                                <div style={{ border: 'none' }} className="item brief">
                                    <p>{artista.brief}</p>
                                </div>

                                <div className="item url">
                                    <a style={{ color: 'white' }} href={artista.url} target="_blank" without rel="noreferrer">{artista.url}</a>
                                    <span>URL</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </section>

    );
}

export default Promotor;