import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import hermoso_ruido_modal from '../../assets/img/Modal/hermoso_ruido_modal.png';
import espiral_izquierda_grande from '../../assets/img/Modal/espiral_izquierda_grande.png';
import espiral_derecha_grande from '../../assets/img/Modal/espiral_izquierda_grande.png';
import cerrar_modal from '../../assets/img/Modal/cerrar_modal.png';


const Mensaje = () => {

    const [redirect, guardarRedirect] = useState(false);

    const animation = () => {
        const mensaje = document.querySelector('#mensaje');
        mensaje.classList.add('fadeout')
        setTimeout(() => {
            guardarRedirect(true)
        }, 500);
    }

    if (redirect) {
        return <Redirect to="/convocatoria" />
    }
    return (
        <section id="mensaje">
            <img className="hermoso_ruido_modal" src={hermoso_ruido_modal} alt="logo" />
            <img className="espiral_derecha_grande" src={espiral_derecha_grande} alt="circulo derecho" />
            <img className="espiral_izquierda_grande" src={espiral_izquierda_grande} alt="circulo izquierdo" />
            <div className="padding ">
                <div className="mensaje_texto">
                    <p>
                        Si no le hubiéramos dado la posibilidad a lo desconocido y si lo nuevo no hubiera sido escuchado en algún punto de la historia, tal vez nunca hubiéramos conocido a las bandas más grandes de la historia de la música.
                    <br /><br />
                     Es por eso que nace Hermoso Ruido; por ese momento único en que el ruido deja de ser ruido y se convierte en música.
                     <br /><br />
                     Hermoso Ruido ha sido una apuesta por lo fresco, por lo nuevo, por los sonidos que moverán al mundo y aún no han sido conocidos.
                     <br /><br />
                     Es la plataforma independiente que brinda a Bogotá, una oferta musical variada, novedosa y fresca.
                  </p>
                </div>

                <div className="mensaje_cerrar">
                    <img className="cerrar_modal" onClick={animation} src={cerrar_modal} alt="Cerrar" />
                    <span >IR A LA CONVOCATORIA</span>
                </div>
            </div>
        </section>
    );
}

export default Mensaje;