import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import tokenAuth from '../config/token'

// crear el context
export const ArtistasContext = createContext();

// Provider = funciones y state
const ArtistasProvider = props => {
    const [artistas, guardarArtistas] = useState([]);

    // llamar a la api
    useEffect(() => {
        const obtenerArtistas = async () => {

            const token = localStorage.getItem('token');
            if(token){
                tokenAuth(token)
                try {
                    const url = 'https://hermoso-ruido.herokuapp.com/api/artistas';
                    const artistas = await axios.get(url);
    
                    guardarArtistas(artistas.data.artistas)
                } catch (error) {
                    console.log(error.response)
                }
            }
            
          
        }
        obtenerArtistas()
    }, [])

    return (
        <ArtistasContext.Provider
            value={{
                artistas,
                guardarArtistas
            }}
        >
            {props.children}
        </ArtistasContext.Provider>
    )
}

export default ArtistasProvider;
