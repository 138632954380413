import React from 'react';
import logo_fhr from '../assets/img/logo_fhr.png';
import espiral_derecho from '../assets/img/espiral_derecho.png';
import espiral_izquierda from '../assets/img/Header/espiral_izquierda_grande.png';
import { Link } from "react-router-dom";

import gracias_circulo from '../assets/img/gracias_circulo.png';
import hermoso_ruido from '../assets/img/Header/hermoso_ruido.png';

const Gracias = () => {



    return (
        <section className="gracias" id="header">
            <div className="menu" id="menu">
                <img className="logo_fhr" src={logo_fhr} alt="Logo" />
                {/* Menu web */}
                <ul className="menu_web">
                    <Link to="/convocatoria" >REGRESAR</Link>
                </ul>


                {/* Menu Movil */}
                <ul className="menu_movil">
                    <Link to="/convocatoria" >REGRESAR</Link>
                </ul>





            </div>

            <img className="espiral_derecho" src={espiral_derecho} alt="circulos" />
            <img className="espiral_izquierda" src={espiral_izquierda} alt="circulos" />
            <img className="gracias_circulo" src={gracias_circulo} alt="pitch" />
            <img className="hermoso_ruido" src={hermoso_ruido} alt="ruido" />
        </section>
    );
}

export default Gracias;