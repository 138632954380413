import React from 'react';

const Calendario = () => {
    return ( 
        <section id="calendario">
            <h2>CALENDARIO</h2>
            <div className="calendario_item">
                <b>lunes 5 a miércoles  <span>14 de abril</span></b>
                <b>[CONVOCATORIA ABIERTA]</b>
            </div>

            <div className="calendario_item">
                <b>jueves 15 a <span>sábado 24 de abril </span></b>
                <b>[Selección de proyectos]</b>

            </div>

            <div className="calendario_item">
                <b> lunes 26 de abril   </b>
                <b>[Anuncio proyectos elegidos]</b>

            </div>

            <div className="calendario_item">
                <b>jueves 29 de abril a jueves <span>13 de mayo </span></b>
                <b>[Revisión portafolios]</b>

            </div>

            <div className="calendario_item">
                <b>jueves 20 y viernes   <span>21 de mayo</span></b>
                <b>[Sesiones de Pitch]</b>

            </div>

            <div className="calendario_item">
                <b>lunes 24 de mayo  </b>
                <b>[Sesión de feedback]</b>

            </div>

            <div className="calendario_item">
                <b>martes 25 al  <span>28 de mayo </span></b>
                <b>[Selección de artistas para FEP]</b>

            </div>
            <div className="calendario_item">
                <b>lunes 1 DE junio</b>
                <b>[Anuncio artistas para FEP]</b>

            </div>
        </section>
     );
}
 
export default Calendario;