import React, { useEffect } from 'react';
import logo_fhr from '../../assets/img/logo_fhr_oscuro.png';
import espiral_derecho from '../../assets/img/espiral_derecho_oscuro.png';
import espiral_izquierda from '../../assets/img/Header/espiral_izquierda_grande_oscuro.png';
import punto_negro_fhr from '../../assets/img/Header/punto_verde_fhr_oscuro.png';
import hermoso_ruido from '../../assets/img/Header/hermoso_ruido_blanco.png';

import { Link } from "react-router-dom";
const HeaderOscuro = () => {




    useEffect(() => {
        const navbar = document.getElementById("menu");
        const sticky = navbar.offsetTop;

        const stickyMeny = () => {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }

        window.onscroll = function () { stickyMeny() };

    }, [])


    return (
        <header id="header">
                <img className="logo_fhr" src={logo_fhr} alt="Logo" />
                <img className="punto_negro_fhr" src={punto_negro_fhr} alt="punto"/>

            <div className="menu" id="menu">
                {/* Menu web */}
                <ul className="menu_web">
                    <Link style={{color: 'white', borderColor: 'white'}} className="inscripcion" to="/sesionespitch/perfil" >VOLVER A LA AGENDA</Link>
                </ul>


                {/* Menu Movil */}
                <ul className="menu_movil">
                    <Link style={{color: 'white', borderColor: 'white'}} className="inscripcion" to="/sesionespitch/perfil" >VOLVER A LA AGENDA</Link>
                </ul>

            </div>

            <img className="espiral_derecho" src={espiral_derecho} alt="circulos" />
            <img className="espiral_izquierda" src={espiral_izquierda} alt="circulos" />
            <img className="hermoso_ruido" src={hermoso_ruido} alt="ruido" />

        </header>
    );
}

export default HeaderOscuro;