import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useParams } from "react-router";
import './Artistas.css'
import logo_youtube from '../../assets/img/artista/logo_youtube.png';
import logo_spotify from '../../assets/img/artista/logo_spotify.png';
import logo_otros_enlaces from '../../assets/img/artista/logo_otros_enlaces.png';
import logo_instagram from '../../assets/img/artista/logo_instagram.png';
import logo_facebook from '../../assets/img/artista/logo_facebook.png';
import {
     Redirect
} from "react-router-dom";

import no_profile_image from '../../assets/img/no_profile_image.png'
import Header from './HeaderOscuro'

const Artista = () => {

    let { id } = useParams();
    const [artista, guardarArtista] = useState({});
    const [cargado, guardarCargando] = useState(false);

    useEffect(() => {
        const obtenerArtista = async () => {
            try {
                const url = `https://hermoso-ruido.herokuapp.com/api/artistas/${id}`;
                const artistaDB = await axios.get(url);
                guardarArtista(artistaDB.data.artista);
                setTimeout(() => {
                    guardarCargando(true)
                }, 500);

            } catch (error) {
                console.log(error)
            }
        }
        obtenerArtista();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const TPERFIL = localStorage.getItem('TPERFIL');
    const AID = localStorage.getItem('AID');
    if (!AID || !TPERFIL) {
        return <Redirect to='/sesionespitch/login' />
    }


    return (
        <section id="artista">
            <div className="background_black"></div>
            <Header />
            {!cargado
                ? 
                <h2 style={{position: 'absolute', 
                color: 'white', 
                textAlign: 'center', 
                width: '100%'}}>
                    Cargando...
                    </h2>
                :
                <div>
                    

                    <div className="artista_brief movil">
                        <h2>{artista.nombre} </h2>
                        <div className="redes_artistas">
                            <div className="redes">

                                <div style={{ width: "35%" }} className="redes_icons">

                                    <a target="_blank" rel="noreferrer" href={artista.youtubeUrl}>
                                        <img src={logo_youtube} alt="youtube" />
                                    </a>
                                    <a target="_blank" rel="noreferrer"  href={artista.spotifyUrl}>
                                        <img src={logo_spotify} alt="spotify" />
                                    </a>
                                </div>

                                <div style={{ width: "45%" }} className="redes_icons">
                                    <a target="_blank" rel="noreferrer"  href={artista.instagramUrl}>
                                        <img src={logo_instagram} alt="instagram" />
                                    </a>

                                    {artista.facebookUrl !== ""
                                        ?
                                        <a target="_blank" rel="noreferrer"  href={artista.facebookUrl}>
                                            <img src={logo_facebook} alt="facebook" />
                                        </a>
                                        :
                                        null

                                    }

                                    {artista.otra !== ""
                                        ?
                                        <a target="_blank" rel="noreferrer"  href={artista.otra}>
                                            <img src={logo_otros_enlaces} alt="otro" />
                                        </a>
                                        :
                                        null

                                    }


                                </div>

                            </div>

                            <div className="desc">
                                <span>links de música</span>
                                <span>redes sociales</span>
                            </div>

                        </div>
                        {!artista.imagenUrl 
                                ?
                                <img src={no_profile_image} alt="Banda" />

                                :
                                <img src={artista.imagenUrl} alt="Banda" />
                                }

                        <div className="item">
                            <div>
                                <p>{artista.email}</p>
                                <span>E-MAIL</span>
                            </div>

                            <p>{artista.telefono}</p>
                            <span>CELULAR</span>

                        </div>

                        {artista.representante !== ""
                            ?
                            <div className="item">
                                <p>{artista.representante}</p>
                                <span>REPRESENTANTE</span>
                            </div>

                            :
                            null
                        }

                        <div className="item">
                            <div>
                                <p>{artista.localidad}</p>
                                <span>LOCALIDAD</span>
                            </div>
                            <p>{artista.trayectoria} </p>
                            <span>AÑOS DE TRAYECTORIA</span>
                        </div>




                        <div className="item">
                            <p>{artista.genero} </p>
                            <span>GÉNERO</span>
                        </div>

                        <div className="item">
                            <p>{artista.brief}</p>
                        </div>

                    </div>
                    

                    <div className="artista_brief desktop">
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: "50%" }}>
                            {!artista.imagenUrl 
                                ?
                                <img src={no_profile_image} alt="Banda" />

                                :
                                <img src={artista.imagenUrl} alt="Banda" />
                                }

                                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="item">
                                    <div>
                                        <p>{artista.email}</p>
                                        <span>E-MAIL</span>
                                    </div>

                                    <div style={{ textAlign: 'right' }}>
                                        <p>{artista.telefono}</p>
                                        <span>CELULAR</span>
                                    </div>

                                </div>

                                {artista.representante !== ""
                                    ?
                                    <div className="item">
                                        <p>{artista.representante}</p>
                                        <span>REPRESENTANTE</span>
                                    </div>

                                    :
                                    null
                                }

                                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="item">
                                    <div>
                                        <p>{artista.localidad}</p>
                                        <span>LOCALIDAD</span>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        <p>{artista.trayectoria} </p>
                                        <span>AÑOS DE TRAYECTORIA</span>
                                    </div>
                                </div>

                                <div className="item">
                                    <p>{artista.genero} </p>
                                    <span>GÉNERO</span>
                                </div>

                            </div>


                            <div style={{ width: "50%", paddingLeft: '15px' }}>
                                <h2>{artista.nombre} </h2>
                                <div className="redes_artistas">
                                    <div className="redes">

                                        <div style={{ width: "20%" }} className="redes_icons">

                                            <a target="_blank" rel="noreferrer"  href={artista.youtubeUrl}>
                                                <img src={logo_youtube} alt="youtube" />
                                            </a>
                                            <a target="_blank" rel="noreferrer"  href={artista.spotifyUrl}>
                                                <img src={logo_spotify} alt="spotify" />
                                            </a>
                                        </div>

                                        <div style={{ width: "20%" }} className="redes_icons">
                                            <a target="_blank" rel="noreferrer"  href={artista.instagramUrl}>
                                                <img src={logo_instagram} alt="instagram" />
                                            </a>

                                            {artista.facebookUrl !== ""
                                                ?
                                                <a target="_blank" rel="noreferrer"  href={artista.facebookUrl}>
                                                    <img src={logo_facebook} alt="facebook" />
                                                </a>
                                                :
                                                null

                                            }

                                            {artista.otra !== ""
                                                ?
                                                <a target="_blank" rel="noreferrer"  href={artista.otra}>
                                                    <img src={logo_otros_enlaces} alt="otro" />
                                                </a>
                                                :
                                                null

                                            }


                                        </div>

                                    </div>

                                    <div className="desc">
                                        <span>links de música</span>
                                        <span>redes sociales</span>
                                    </div>

                                </div>

                                <div style={{border: 'none'}} className="item">
                                    <p>{artista.brief}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </section>

    );
}

export default Artista;