import React, { useContext, useEffect, useState } from 'react';
import { ArtistasContext } from '../../context/ArtistasContext';
import {
    Link
} from "react-router-dom";
import './Artistas.css'
import Header from './Header'
import { Redirect } from "react-router-dom";

import no_profile_image from '../../assets/img/no_profile_image.png'

const no_profile_image_const = no_profile_image;

const Artistas = () => {
    const { artistas } = useContext(ArtistasContext);
    const [cargado, guardarCargando] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            guardarCargando(true)
        }, 600);
    }, []);

    const token = localStorage.getItem('token');
    if (!token ) {
        return <Redirect to='/admin-login' />
    }

    return (
        <section id="artistas">
            <Header />

            {!cargado
                ?
                <h2 style={{
                    position: 'absolute',
                    color: 'black',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    Cargando...
                    </h2>
                :

                <div style={{maxWidth: '800px', margin: '0px auto'}}>
                    <h2 className="titulo">GALERÍA DE ARTISTAS</h2>
                    {artistas.map((artista, i) => (
                        <div id={"artista_" + i} className="artista_brief padding" key={artista._id}>
                            <div className="flex">
                                <div className="artista_nombre">
                                    <h2>{artista.nombre} </h2>

                                    <div >
                                        {i < 9 ?
                                            <b>[ 0{i + 1} ]</b>
                                            :
                                            <b>[ {i + 1} ] </b>
                                        }
                                    </div>

                                </div >
                                {!artista.imagenUrl 
                                ?
                                <div className="artista_profile" style={{ backgroundImage: `url(${no_profile_image_const})` }}></div>

                                :
                                <div className="artista_profile" style={{ backgroundImage: `url(${artista.imagenUrl})` }}></div>

                                }
                            </div>

                            <Link target="_blank" rel="noreferrer"   to={`/artistas/artista/${artista._id}`}>Ver perfil</Link>

                        </div>

                    ))}
                </div>

            }

        </section>
    );
}

export default Artistas;