import React, { useEffect } from 'react';
import logo_fhr from '../../assets/img/logo_fhr.png';
import espiral_derecho from '../../assets/img/espiral_derecho.png';
import espiral_izquierda from '../../assets/img/Header/espiral_izquierda_grande.png';
import punto_negro_fhr from '../../assets/img/Header/punto_negro_fhr.png';

import sesiones_de_pitch from '../../assets/img/Header/sesiones_de_pitch.png';
import hermoso_ruido from '../../assets/img/Header/hermoso_ruido.png';

import { Link } from "react-scroll";
const Header = () => {




    useEffect(() => {
        const navbar = document.getElementById("menu");
        const sticky = navbar.offsetTop;

        const stickyMeny = () => {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }

        window.onscroll = function () { stickyMeny() };

    }, [])

    const showMenu = () =>{
        const menu_movil = document.querySelector(".menu_movil");
        const padding = document.querySelector(".padding");
        const sesiones_de_pitch = document.querySelector(".sesiones_de_pitch");
        const menu_movil_show = document.querySelector(".menu_movil_show");
        const menu_cerrar = document.querySelector(".menu_cerrar");
        const body = document.querySelector("body");
        

        menu_movil.classList.toggle("animacion_menu");
        menu_cerrar.classList.toggle("animacion_menu_cerrar");
        padding.classList.toggle("fadeout");    
        sesiones_de_pitch.classList.toggle("fadeout");    
         
        if(menu_movil_show.classList.contains("fadein")){
            menu_movil_show.classList.remove("fadein");  
            body.style.overflow = "visible";

        }else{
            body.style.overflow = "hidden";
            setTimeout(() => {
                menu_movil_show.classList.add("fadein");   
            }, 500);
        }

    }

    return (
        <header id="header">
                <img className="logo_fhr" src={logo_fhr} alt="Logo" />
                <img className="punto_negro_fhr" src={punto_negro_fhr} alt="punto"/>

            <div className="menu" id="menu">
                {/* Menu web */}
                <ul className="menu_web">
                    <Link to="convocatoria" smooth={true}>LA CONVOCATORIA</Link>
                    <Link to="criterios" smooth={true}>CRITERIOS</Link>
                    <Link to="jurados" smooth={true}>JURADOS</Link>
                    {/* <Link to="promotores" smooth={true}>PROMOTORES</Link> */}
                    <Link to="calendario" smooth={true}>CALENDARIO</Link>
                    <Link className="inscripcion" to="registro_artista" smooth={true}>INSCRIPCIÓN</Link>
                </ul>


                {/* Menu Movil */}
                <ul className="menu_movil">
                    <Link onClick={showMenu}>MENU</Link>
                    <Link className="inscripcion" to="registro_artista" smooth={true}>INSCRIPCIÓN</Link>
                </ul>

                <ul className="menu_cerrar">
                    <Link onClick={showMenu}>CERRAR</Link>
                </ul>

                <ul className="menu_movil_show">
                    <Link  to="convocatoria" smooth={true} onClick={showMenu}>LA CONVOCATORIA</Link>
                    <Link  to="criterios" smooth={true} onClick={showMenu}>CRITERIOS</Link>
                    <Link  to="jurados" smooth={true} onClick={showMenu}>JURADOS</Link>
                    {/* <Link  to="promotores" smooth={true} onClick={showMenu}>PROMOTORES</Link> */}
                    <Link  to="calendario" smooth={true} onClick={showMenu}>CALENDARIO</Link>
                    <Link  className="inscripcion" to="registro_artista" smooth={true} onClick={showMenu}>INSCRIPCIÓN</Link>
                </ul>


            </div>

            <img className="espiral_derecho" src={espiral_derecho} alt="circulos" />
            <img className="espiral_izquierda" src={espiral_izquierda} alt="circulos" />
            <img className="sesiones_de_pitch" src={sesiones_de_pitch} alt="pitch" />
            <img className="hermoso_ruido" src={hermoso_ruido} alt="ruido" />

        </header>
    );
}

export default Header;