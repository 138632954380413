import React from 'react';

const Jurados = () => {
    return (
        <section id="jurados">
            <h2>JURADOS DE SELECCIÓN</h2>
            <b className="booking"><span>[Booking </span>  <span> Páramo</span> <span> Presenta]</span> </b>
            <p>
                Philippe Siegenthaler. <br />
                Sergio Pabón. <br />
                Anamaría Díaz. <br />
                Catalina Amador. <br />
                Laura Vásquez.</p>
        </section>
    );
}

export default Jurados;