import React from 'react';
import { ZoomMtg } from "@zoomus/websdk";
import { useEffect } from "react";
import { useParams } from "react-router";



const ZoomArtista = () => {
    let { meet, usuario } = useParams();

    const crypto = require("crypto"); // crypto comes with Node.js

    function generateSignature(apiKey, apiSecret, meetingNumber, role) {
        return new Promise((res, rej) => {
            // Prevent time sync issue between client signature generation and zoom
            const timestamp = new Date().getTime() - 30000;
            const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
                "base64"
            );
            const hash = crypto
                .createHmac("sha256", apiSecret)
                .update(msg)
                .digest("base64");
            const signature = Buffer.from(
                `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
            ).toString("base64");

            res(signature);
        });
    }


    var apiKey = "ShorgO7HRFSANRYNzeaA9Q";
    var apiSecret = "nZlZiWTsVS7DRgSjCv0mKv11fZbKD4f54p7Q";
    var meetingNumber = meet;
    var leaveUrl = "http://festivalhermosoruido.com/#/sesionespitch/perfil"; // our redirect url
    var userEmail = "";
    var passWord = "252234";

    var signature = "";
    generateSignature(apiKey, apiSecret, meetingNumber, 0).then((res) => {
        signature = res;

    }); // need to generate based on meeting id - using - role by default 0 = javascript


    // loading zoom libraries before joining on component did mount
    useEffect(() => {
        showZoomDIv();
        ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.1/lib', '/av');
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
        initiateMeeting();
    }, []);

    const showZoomDIv = () => {
        document.getElementById("zmmtg-root").style.display = "block";
    };

    const initiateMeeting = () => {
        ZoomMtg.init({
            leaveUrl: leaveUrl,
            isSupportAV: true,
            success: (success) => {
                console.log('Hola' + success);

                ZoomMtg.join({
                    signature: signature,
                    meetingNumber: meet,
                    userName: usuario,
                    apiKey: apiKey,
                    userEmail: userEmail,
                    passWord: passWord,
                    success: (success) => {
                        console.log(success);
                    },
                    error: (error) => {
                        console.log('hola soy' + error);
                    },
                });
            },
            error: (error) => {
                console.log('hola soy' + error);
            },
        });
    };

    return <div className="App">
        <div className="texto_zoom">Para volver a la Agenda, finalice la llamada haciendo clic en End</div>
    </div>;
};

export default ZoomArtista;