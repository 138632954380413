import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Artistas from './components/Artistas/Artistas';
import Artista from './components/Artistas/Artista';
import LoginAdmin from './components/Artistas/LoginAdmin';
import ArtistasProvider from './context/ArtistasContext';
import Home from './components/Inicio/Home';
import Mensaje from './components/Inicio/Mensaje';
import Gracias from './components/Gracias';
import Zoom from './components/Zoom';
import ZoomArtista from './components/ZoomArtista';
import IniciarPerfil from './components/Perfiles/IniciarPerfil';
import Perfil from './components/Perfiles/Perfil';
import Promotor from './components/Artistas/Promotor';



import AuthState from './context/autenticacion/authState'
import tokenAuth from './config/token'



const App = () => {


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      tokenAuth(token);
    }
  }, []);

  return (
    <AuthState>
      <div className="background"></div>
      <Router>
        <div>
          <Switch>

            <Route exact path="/artistas">
              <ArtistasProvider>
                <Artistas />
              </ArtistasProvider>
            </Route>

            <Route exact path="/">
              <Mensaje />
            </Route>

            <Route exact path="/convocatoria">
              <Home />
            </Route>

            <Route exact path="/gracias">
              <Gracias />
            </Route>

            <Route exact path="/admin-login">
              <LoginAdmin />
            </Route>

            <Route exact path="/sesionespitch/artista/:id">
              <Artista />
            </Route>
            <Route exact path="/sesionespitch/promotor/:id">
              <Promotor />
            </Route>
            
            <Route exact path="/sesionespitch/zoomPro/:meet/:usuario">
              <Zoom />
            </Route>

            <Route exact path="/sesionespitch/zoomArt/:meet/:usuario">
              <ZoomArtista />
            </Route>

            <Route exact path="/sesionespitch/login">
              <IniciarPerfil />
            </Route>

            <Route exact path="/sesionespitch/perfil">
              <Perfil />
            </Route>

            




          </Switch>
        </div>
      </Router>
      
    </AuthState>


  );
}

export default App;
