import React, { useState } from 'react';
import axios from 'axios'
import { Redirect } from "react-router-dom";


const RegistroArtista = () => {

    const [artista, guardarArtista] = useState({
        nombre: '',
        representante: '',
        email: '',
        telefono: '',
        facebookUrl: '',
        instagramUrl: '',
        otra: '',
        spotifyUrl: '',
        youtubeUrl: '',
        brief: '',
        trayectoria: '',
        localidad: '',
        genero: ''
    });

    const { nombre, email, telefono, instagramUrl, spotifyUrl, youtubeUrl, brief, trayectoria, localidad, genero } = artista;



    const [imagen, guardarImagen] = useState(null);
    const [imagenError, guardarImagenError] = useState('');
    const [exito, guardarExito] = useState('false');



    const obtenerDatosArtista = e => {

        guardarArtista({
            ...artista,
            [e.target.name]: e.target.value
        })

    }

    const submitArtista = async e => {
        e.preventDefault();

        const general = document.querySelector('#general');
        const error_imagen = document.querySelector('#error_imagen');
        const error_telefono = document.querySelector('#error_telefono');


        const set = () => {
            setTimeout(() => {
                general.classList.remove('fadein')
                error_imagen.classList.remove('fadein')
                error_telefono.classList.remove('fadein')
            }, 8000);
        }

        // Variables para los inputs
        const nombreId = document.querySelector('#nombre');
        const emailId = document.querySelector('#email');
        const telefonoId = document.querySelector('#telefono');
        const instagramUrlId = document.querySelector('#instagramUrl');
        const spotifyUrlId = document.querySelector('#spotifyUrl');
        const youtubeUrlId = document.querySelector('#youtubeUrl');
        const briefId = document.querySelector('#brief');
        const localidadId = document.querySelector('#localidad');
        const trayectoriaId = document.querySelector('#trayectoria');
        const generoId = document.querySelector('#genero');


        if (nombre === "") {
            nombreId.classList.add('red')
        } else {
            nombreId.classList.remove('red')
        }

        if (email === "") {
            emailId.classList.add('red')
        } else {
            emailId.classList.remove('red')
        }

        if (telefono === "") {
            telefonoId.classList.add('red')
        } else {
            telefonoId.classList.remove('red')
        }

        if (isNaN(telefono)) {
            error_telefono.classList.add('fadein');
        }


        if (instagramUrl === "") {
            instagramUrlId.classList.add('red')
        } else {
            instagramUrlId.classList.remove('red')
        }

        if (spotifyUrl === "") {
            spotifyUrlId.classList.add('red')
        } else {
            spotifyUrlId.classList.remove('red')
        }
        if (youtubeUrl === "") {
            youtubeUrlId.classList.add('red')
        } else {
            youtubeUrlId.classList.remove('red')
        }
        if (brief === "") {
            briefId.classList.add('red')
        } else {
            briefId.classList.remove('red')
        }
        if (localidad === "") {
            localidadId.classList.add('red')
        } else {
            localidadId.classList.remove('red')
        }

        if (trayectoria === "") {
            trayectoriaId.classList.add('red')
        } else {
            trayectoriaId.classList.remove('red')
        }

        if (genero === "") {
            generoId.classList.add('red')
        } else {
            generoId.classList.remove('red')
        }

        // validar el formulario
        if (nombre === "" || email === "" || telefono === "" || instagramUrl === "" || spotifyUrl === "" || youtubeUrl === "" || brief === "" || localidad === "" || trayectoria === "" || genero === "") {
            general.classList.add('fadein');
            return set();
        }

        if (imagen === null) {
            guardarImagenError('Debes seleccionar una imagen');
            error_imagen.classList.add('fadein');
            return set();
        }

        if (imagen !== null) {
            if (imagen.size > 2000000) {
                guardarImagenError('La imagen no puede pasar de los 2MB');
                return error_imagen.classList.add('fadein');
            }

            let formato = imagen.type;

            if (formato === "image/jpeg" || formato === "image/png" || formato === "image/jpg") {
                guardarExito('cargando')

                const url = 'https://hermoso-ruido.herokuapp.com/api/artistas';
                const artistaNuevo = await axios.post(url, {
                    nombre: artista.nombre,
                    representante: artista.representante,
                    email: artista.email,
                    telefono: artista.telefono,
                    facebookUrl: artista.facebookUrl,
                    instagramUrl: artista.instagramUrl,
                    otra: artista.otra,
                    spotifyUrl: artista.spotifyUrl,
                    youtubeUrl: artista.youtubeUrl,
                    brief: artista.brief,
                    localidad: artista.localidad,
                    trayectoria: artista.trayectoria,
                    genero: artista.genero
                })

                const id = artistaNuevo.data.artista._id;

                // GUARDAR IMAGEN
                const formData = new FormData();
                formData.append(
                    'archivo',
                    imagen,
                    imagen.name
                );

                const urlImagen = `https://hermoso-ruido.herokuapp.com/api/upload/artista/${id}`
                await axios.put(urlImagen,
                    formData
                )

                guardarExito('true')


            } else {
                guardarImagenError('Formato no válido. Por favor enviar en formatos: jpg, png o jpeg');
                error_imagen.classList.add('fadein');
                return set();
            }

        }


    }

    if (exito === 'true') {

        return <Redirect to='/gracias' />
    }
    return (
        <section  id="registro_artista">
            <h2 style={{textAlign: 'center'}}>INSCRIPCIÓN</h2>
            <b style={{
                letterSpacing: '9px', paddingBottom: '100px',
                display: 'block'
            }}>[LA CONVOCATORIA HA CERRADO]</b>
            <form
                id="form"
                onSubmit={submitArtista}
            >
                <div>
                    <label htmlFor="nombre">Nombre de la banda<span className="required">*</span> </label>
                    <input
                        type="text"
                        name="nombre"
                        id="nombre"
                        onChange={obtenerDatosArtista}
                    />
                </div>


                <div>
                    <label htmlFor="nombre">Descripción del proyecto<span className="required">*</span></label>
                    <textarea
                        name="brief"
                        cols="30"
                        rows="10"
                        id="brief"
                        onChange={obtenerDatosArtista}
                        placeholder="Breve reseña de máximo 250 palabras."

                    ></textarea>
                </div>

                <div>
                    <b>Links de música<span className="required">*</span></b>
                </div>

                <div className="flex_input">
                    <span>[canción en spotify]</span>
                    <input
                        type="url"
                        name="spotifyUrl"
                        id="spotifyUrl"
                        placeholder="URL de canción en Spotify o alguna otra plataforma de streaming"
                        onChange={obtenerDatosArtista}
                    />
                </div>
                <div className="flex_input">
                    <span>[video en youtube]</span>
                    <input
                        type="url"
                        name="youtubeUrl"
                        id="youtubeUrl"
                        placeholder="URL de un video"
                        onChange={obtenerDatosArtista}
                    />
                </div>

                <div>
                    <b>Redes sociales<span className="required">*</span></b>
                </div>

                <div className="flex_input">
                    <span>[instagram]</span>
                    <input
                        type="url"
                        name="instagramUrl"
                        id="instagramUrl"
                        placeholder="URL de tu perfil"
                        onChange={obtenerDatosArtista}
                    />
                </div>

                <div className="flex_input">
                    <span>[facebook]</span>
                    <input
                        type="url"
                        name="facebookUrl"
                        id="facebookUrl"
                        placeholder="URL de tu perfil"
                        onChange={obtenerDatosArtista}
                    />
                </div>


                <div className="flex_input">
                    <span>[otra]</span>
                    <input
                        type="url"
                        name="otra"
                        id="otra"
                        placeholder="URL de tu perfil"
                        onChange={obtenerDatosArtista}
                    />
                </div>

                <div>
                    <b>Contacto<span className="required">*</span></b>
                </div>

                <div className="flex_input">
                    <span>[e-mail]</span>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Correo de la banda o representante"
                        onChange={obtenerDatosArtista}
                    />
                </div>


                <div className="flex_input">
                    <span>[celular]</span>
                    <input
                        type="text"
                        name="telefono"
                        id="telefono"
                        placeholder="Número telefónico"
                        onChange={obtenerDatosArtista}
                    />
                </div>


                <div className="flex_input">
                    <span>[nombre representante]</span>

                    <input
                        type="text"
                        name="representante"
                        id="representante"
                        placeholder="La persona que va a asistir a las Sesiones de pitch (opcional)"
                        onChange={obtenerDatosArtista}
                    />
                </div>


                <div>
                    <label htmlFor="localidad">Años de trayectoria<span className="required">*</span></label>
                    <input
                        type="number"
                        name="trayectoria"
                        id="trayectoria"
                        placeholder="Número de años activos"
                        onChange={obtenerDatosArtista}
                    />

                </div>

                <div>
                    <label htmlFor="localidad">Localidad<span className="required">*</span></label>
                    <select
                        name="localidad"
                        id="localidad"
                        onChange={obtenerDatosArtista}
                    >
                        <option value="">Selecciona una del listado</option>
                        <option value="Antonio Nariño">Antonio Nariño</option>
                        <option value="Barrios Unidos">Barrios Unidos </option>
                        <option value="Bosa">Bosa </option>
                        <option value="Chapinero">Chapinero </option>
                        <option value="Ciudad Bolívar">Ciudad Bolívar </option>
                        <option value="Engativá">Engativá </option>
                        <option value="Fontibón">Fontibón </option>
                        <option value="Kennedy">Kennedy </option>
                        <option value="La Candelaria">La Candelaria </option>
                        <option value="Los Mártires">Los Mártires </option>
                        <option value="Puente Aranda">Puente Aranda </option>
                        <option value="Rafael Uribe Uribe">Rafael Uribe Uribe </option>
                        <option value="San Cristóbal">San Cristóbal </option>
                        <option value="Santa Fe">Santa Fe </option>
                        <option value="Suba">Suba </option>
                        <option value="Sumapaz">Sumapaz </option>
                        <option value="Teusaquillo">Teusaquillo </option>
                        <option value="Tunjuelito">Tunjuelito </option>
                        <option value="Usaquén">Usaquén </option>
                        <option value="Usme">Usme </option>
                        <option value="Bogotá Región">Bogotá Región </option>

                    </select>

                </div>

                <div>
                    <label htmlFor="genero">Género<span className="required">*</span></label>
                    <input
                        type="text"
                        name="genero"
                        id="genero"
                        placeholder="Estilo musical de la banda"
                        onChange={obtenerDatosArtista}
                    />
                </div>

                <div>
                    <label htmlFor="archivo">Foto<span className="required">*</span></label>
                    <span>*Resolución sugerida 1280x720, 2 MB</span>
                    <input
                        type="file"
                        name="archivo"
                        id="archivo"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => guardarImagen(e.target.files[0])}

                    />
                </div>
                <div className="flex_input">
                    {
                        exito === 'false' ?
                            <input
                                type="submit"
                                value="Enviar"

                            />

                            :
                            null


                    }

                </div>

                {exito === 'cargando'
                    ? <div className="mensajeEspera"><p>Estamos cargando tu perfil. Espera un momento, por favor.</p></div>
                    : null
                }
                <div className="mensajeError" id="general"><p>Te faltan campos obligatorios por completar.</p></div>
                <div className="mensajeError" id="error_imagen"><p>{imagenError} </p></div>
                <div className="mensajeError" id="error_telefono"><p>Ingresa un número de celular válido.</p></div>
            </form>
        </section>
    );
}

export default RegistroArtista;