import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import axios from 'axios'

import {
    LOGIN_EXISTOSO,
    LOGIN_EXISTOSO_PERFIL,
    LOGIN_ERROR
} from '../../types'

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        TPERFIL: localStorage.getItem('TPERFIL'),
        AID: localStorage.getItem('AID'),
        autenticado: null,
        admin: null,
        mensaje: null
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    // las funciones
    // Cuando el admin inicia sesión
    const iniciarSesion = async datos => {

        try {
            const respuesta = await axios.post('https://hermoso-ruido.herokuapp.com/api/auth-administrador', datos);

            dispatch({
                type: LOGIN_EXISTOSO,
                payload: respuesta.data.token
            })

        } catch (error) {
            dispatch({
                type: LOGIN_ERROR,
                payload: "Datos no válidos"
            })

            setTimeout(() => {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: null
                })
            }, 2000);

        }
    }

    // Iniciar sesión para los perfiles

    const iniciarSesionPerfil = async datos => {

        try {
            const respuesta = await axios.post('https://hermoso-ruido.herokuapp.com/api/auth-perfil', datos);

            console.log(respuesta.data)

            dispatch({
                type: LOGIN_EXISTOSO_PERFIL,
                payload: respuesta.data
            })

        } catch (error) {
            dispatch({
                type: LOGIN_ERROR,
                payload: "Datos no válidos"
            })

            setTimeout(() => {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: null
                })
            }, 2000);

        }
    }

    return (
        <AuthContext.Provider
            value={{
                TPERFIL: state.TPERFIL,
                token: state.token,
                AID: state.AID,
                autenticado: state.autenticado,
                admin: state.admin,
                mensaje: state.mensaje,
                iniciarSesion,
                iniciarSesionPerfil
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )

}

export default AuthState;