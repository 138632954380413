import React from 'react';

const Convocatoria = () => {
    return (
        <section id="convocatoria">
            <h2>ARTISTAS SELECCIONADOS</h2>
            <div className="descargar">[  <a target="_blank" href="http://www.festivalhermosoruido.com/ArtistasSeleccionados.pdf">DESCARGAR</a>  ]</div>


            <h2>LA CONVOCATORIA</h2>
            <div className="convocatoria_contenedor">
                <p>Sesiones de Pitch es la Convocatoria abierta de Hermoso Ruido que busca seleccionar 30 bandas de BOGOTÁ y BOGOTÁ - REGIÓN    para hacer parte de un encuentro virtual con más de 15 promotores  de música. 2 de las 30 bandas     seleccionadas, serán elegidas a su vez, para ser parte de la próxima edición del Festival Estéreo Picnic.
                    <br /><br />

                     Las propuestas seleccionadas serán acompañadas y guiadas por el equipo de Páramo para prepararse antes de presentar sus proyectos ante los promotores de una forma clara y robusta. Todas las propuestas tendrán la oportunidad de reunirse con al menos 15 de los 20 promotores participantes en el mes de mayo 2021. Una vez terminadas las sesiones de Pitch, las bandas podrán tener una sesión de feedback del proceso con el equipo de Páramo.
                     <br/><br/>
                      La selección de las propuestas responderá a criterios artísticos, de calidad y solidez en la presentación, innovación y actualidad y estará a cargo del equipo de booking de Páramo Presenta.</p>
                <b>*Se elegirán 20 bandas por convocatoria y 10 bandas <br /> de la edición 2020 del Festival Hermoso Ruido.</b>
            </div>
        </section>
    );
}

export default Convocatoria;