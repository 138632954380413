import React from 'react';
import '../../App.css'

import Header from './Header'
import Convocatoria from './Convocatoria'
import Criterios from './Criterios'
import Jurados from './Jurados'
import Calendario from './Calendario'
import RegistroArtista from './RegistroArtista'
import Promotores from './Promotores'
import logo_footer_idartes from '../../assets/img/logo_footer_idartes.png'

const Home = () => {
   return (
      <section id="home">

         <Header />
         <div className="padding">
            <Convocatoria />
            <Criterios />
            <Jurados />
            {/* <Promotores /> */}
            <Calendario />
            <RegistroArtista />
            <footer>
               <img src={logo_footer_idartes} alt="logo footer" />
            </footer>

         </div>


      </section>
   );
}

export default Home;