import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header'
import './Perfiles.css'
import {
    Link, Redirect
} from "react-router-dom";
import no_profile_image from '../../assets/img/no_profile_image.png'
const no_profile_image_const = no_profile_image;

const Perfil = () => {

    const [agenda, guardarAgenda] = useState({});
    const [cargado, guardarCargando] = useState(false);
    const [artistas, guardarArtistas] = useState([]);
    const [promotores, guardarPromotores] = useState([]);
    const [menu, guardarMenu] = useState('');


    useEffect(() => {
        showZoomDIv();

        const obtenerAgenda = async () => {
            const TPERFIL = localStorage.getItem('TPERFIL');
            const AID = localStorage.getItem('AID');

            if (TPERFIL && AID) {
                try {
                    const url = `https://hermoso-ruido.herokuapp.com/api/agendas/${AID}`;
                    const agenda = await axios.get(url);
                    guardarAgenda(agenda.data.agenda)

                } catch (error) {
                    console.log(error.response)
                }
            }

            setTimeout(() => {
                guardarCargando(true)
            }, 600);


        }
        obtenerAgenda()
        const obtenerArtistas = async () => {

            const TPERFIL = localStorage.getItem('TPERFIL');
            const AID = localStorage.getItem('AID');
            if (TPERFIL && AID) {
                try {
                    const url = 'https://hermoso-ruido.herokuapp.com/api/artistas';
                    const artistas = await axios.get(url);

                    guardarArtistas(artistas.data.artistas)
                } catch (error) {
                    console.log(error.response)
                }
            }
        }
        if (agenda.tipo === "Promotor") {
            obtenerArtistas()
            guardarMenu('GALERÍA DE ARTISTAS')
        }

        const obtenerPromotores = async () => {

            const TPERFIL = localStorage.getItem('TPERFIL');
            const AID = localStorage.getItem('AID');
            if (TPERFIL && AID) {
                try {
                    const url = 'https://hermoso-ruido.herokuapp.com/api/promotores';
                    const promotores = await axios.get(url);

                    guardarPromotores(promotores.data.promotores)
                } catch (error) {
                    console.log(error.response)
                }
            }
        }
        if (agenda.tipo === "Artista") {
            obtenerPromotores()
            guardarMenu('GALERÍA DE PROMOTORES')
        }

    }, [agenda.tipo])

    const showZoomDIv = () => {
        document.getElementById("zmmtg-root").style.display = "none";
    };


    const TPERFIL = localStorage.getItem('TPERFIL');
    const AID = localStorage.getItem('AID');
    if (!AID || !TPERFIL) {
        return <Redirect to='/sesionespitch/login' />
    }


    if (Object.keys(agenda).length) {
        agenda.jueves.sort(function (a, b) {
            const horaA = a.hora;
            const horaAdiv = horaA.split(':');
            const horaAUni = horaAdiv.join('.');

            const horaB = b.hora;
            const horaBdiv = horaB.split(':');
            const horaBUni = horaBdiv.join('.');

            return parseFloat(horaAUni) - parseFloat(horaBUni)
                  
        });
        

        agenda.viernes.sort(function (a, b) {
            const horaA = a.hora;
            const horaAdiv = horaA.split(':');
            const horaAUni = horaAdiv.join('.');

            const horaB = b.hora;
            const horaBdiv = horaB.split(':');
            const horaBUni = horaBdiv.join('.');

            return parseFloat(horaAUni) - parseFloat(horaBUni)
                  
        });
    }


    return (

        <section id="perfiles">
            <Header
                menu={menu}
            />

            {!cargado
                ?
                <h2 style={{
                    position: 'absolute',
                    color: 'black',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    Cargando...
                    </h2>
                :

                <div id="agenda" style={{ maxWidth: '800px', margin: '0px auto' }}>
                    <h2 className="titulo">MI AGENDA</h2>

                    {!Object.keys(agenda).length
                        ?
                        null
                        :

                        <div className="agenda">
                            <p className="dia">[jueves, mayo 20 2021]</p>

                            {agenda.jueves.map((hora, index) => (

                                <div className="hora">
                                    <div className="flex desktop">
                                        <div>
                                            <p className="titulo">{hora.artista}</p>
                                            <p className="fecha">MAYO 20 2021</p>
                                            <span>{hora.hora}</span>
                                        </div>
                                        <a target="_blank"  href={hora.zoomUrl} rel="noreferrer" >IR A LA SALA</a>
                                    </div>

                                    <div className="movil">
                                        <p className="titulo">{hora.artista}</p>
                                        <div className="flex">
                                            <span><p>MAYO 20 2021</p> {hora.hora}</span>
                                            <a target="_blank"   href={hora.zoomUrl} rel="noreferrer">IR A LA SALA</a>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <p style={{ marginTop: '50px' }} className="dia">[viernes, mayo 21 2021]</p>
                            {agenda.viernes.map((hora, index) => (
                                <div className="hora">
                                    <div className="flex desktop">
                                        <div>
                                            <p className="titulo">{hora.artista}</p>
                                            <p className="fecha">MAYO 21 2021</p>
                                            <span>{hora.hora}</span>
                                        </div>
                                        <a target="_blank"  href={hora.zoomUrl} rel="noreferrer">IR A LA SALA</a>
                                    </div>

                                    <div className="movil">
                                        <p className="titulo">{hora.artista}</p>
                                        <div className="flex">
                                            <span><p>MAYO 21 2021</p> {hora.hora}</span>
                                            <a target="_blank"  href={hora.zoomUrl} rel="noreferrer">IR A LA SALA</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    }

                    {agenda.tipo === "Promotor"
                        ?

                        <div id="galeria" style={{ maxWidth: '800px', margin: '0px auto' }}>
                            <h2 className="titulo">GALERÍA DE ARTISTAS
                            <p style={{marginTop: '15px'}}>Estos son todos los artistas que hacen parte de Sesiones de Pitch. Consulte sus perfiles abajo.</p>
                            </h2>
                            
                            {artistas.map((artista, i) => (
                                <div id={"artista_" + i} className="artista_brief padding" key={artista._id}>
                                    <div className="flex">
                                        <div className="artista_nombre">
                                            <h2>{artista.nombre} </h2>

                                            <div >
                                                {i < 9 ?
                                                    <b>[ 0{i + 1} ]</b>
                                                    :
                                                    <b>[ {i + 1} ] </b>
                                                }
                                            </div>

                                        </div >
                                        {!artista.imagenUrl
                                            ?
                                            <div className="artista_profile" style={{ backgroundImage: `url(${no_profile_image_const})` }}></div>

                                            :
                                            <div className="artista_profile" style={{ backgroundImage: `url(${artista.imagenUrl})` }}></div>

                                        }
                                    </div>

                                    <Link  to={`/sesionespitch/artista/${artista._id}`}>Ver perfil</Link>

                                </div>

                            ))}

                            <div className="clearfix"></div>
                        </div>
                        :
                        null
                    }

                    {agenda.tipo === "Artista"
                        ?

                        <div id="galeria" style={{ maxWidth: '800px', margin: '0px auto' }}>
                            <h2 className="titulo">GALERÍA DE PROMOTORES
                            <p style={{marginTop: '15px'}}>Estos son los todos los promotores que hacen parte de Sesiones de Pitch. Consulte sus perfiles abajo.</p>
                            </h2>
                            
                            {promotores.map((promotor, i) => (
                                <div id={"artista_" + i} className="artista_brief padding" key={promotor._id}>
                                    <div className="flex">
                                        <div className="artista_nombre">
                                            <h2>{promotor.nombre} </h2>

                                            <div >
                                                {i < 9 ?
                                                    <b>[ 0{i + 1} ]</b>
                                                    :
                                                    <b>[ {i + 1} ] </b>
                                                }
                                            </div>

                                        </div >
                                        {!promotor.imagenUrl
                                            ?
                                            <div className="artista_profile" style={{ backgroundImage: `url(${no_profile_image_const})` }}></div>

                                            :
                                            <div className="artista_profile" style={{ backgroundImage: `url(${promotor.imagenUrl})` }}></div>

                                        }
                                    </div>

                                    <Link to={`/sesionespitch/promotor/${promotor._id}`}>Ver perfil</Link>

                                </div>

                            ))}
                            <div className="clearfix"></div>

                        </div>

                        :

                        null

                    }


                </div>

            }

        </section>

    );
}

export default Perfil;