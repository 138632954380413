import React from 'react';

const Criterios = () => {
    return (
        <section id="criterios">
            <h2>CRITERIOS DE SELECCIÓN</h2>
            <b>Los proyectos musicales se valorarán con un máximo de 10 PUNTOS, teniendo en cuenta los siguientes criterios:</b>
            
            <div className="border">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="criterios_contenedor">
                <div className="criterio_item">
                    <p>Calidad y trayectoria de la propuesta.</p>
                    <b>[4 PUNTOS]</b>
                </div>

                <div className="criterio_item">
                    <p>Originalidad e innovación de la propuesta.</p>
                    <b>[3 PUNTOS]</b>
                </div>

                <div className="criterio_item">
                    <p>Encaje y viabilidad de la propuesta en el mercado.</p>
                    <b>[3 PUNTOS]</b>
                </div>
            </div>
        </section>
    );
}

export default Criterios;