import {
    LOGIN_EXISTOSO,
    LOGIN_EXISTOSO_PERFIL,
    LOGIN_ERROR
} from '../../types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case LOGIN_EXISTOSO:
            localStorage.setItem('token', action.payload);
            return {
                ...state,
                autenticado: true,
                mensaje: null
            }
        case LOGIN_EXISTOSO_PERFIL:
            localStorage.setItem('TPERFIL', action.payload.token);
            localStorage.setItem('AID', action.payload.agendaId);
            return {
                ...state,
                autenticado: true,
                mensaje: null
            }
        case LOGIN_ERROR:
            localStorage.removeItem('token')
            return {
                ...state,
                autenticado: null,
                token: null,
                mensaje: action.payload
            }

        default:
            return state
    }
}