import clienteAxios from './axios';

const tokenAuth = (token) => {
    if(token){
        clienteAxios.defaults.headers.common['x-auth-token'] = token;
        console.log(clienteAxios.defaults.headers.common)

    }else{
        delete clienteAxios.defaults.headers.common['x-auth-token'];
        console.log(clienteAxios.defaults.headers.common)

    }
}

export default tokenAuth;